<template>
  <div class="col-md-12" v-if="$parent.itinerarios.length > 0">
    <div class="card card-outline card-navy mb-0">
      <div class="card-header pt-2 pb-2">
        <h3 class="card-title">
          <h6><i class="fas fa-map"></i> Itinerario</h6>
        </h3>
        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body table-responsive">
        <div class="row">
          <div class="form-group col-md-12 text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#modal_itinerario"
                @click="getDatosModal()"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <table
              id="tabla-pasajeros"
              class="
                table table-bordered table-striped table-hover table-sm
                m-0
              "
            >
              <thead class="thead-dark">
                <tr>
                  <th class="text-center">Tipo Servicio</th>
                  <th class="text-center">Fecha</th>
                  <th class="text-center">Ruta</th>
                  <th class="text-center">Tipo Vehículo</th>
                  <th class="text-center">Transportadora</th>
                  <th class="text-center">CECO / AFE</th>
                  <th class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(itin, index) in $parent.itinerarios" :key="index">
                  <td class="text-center">
                    <span class="badge"
                      :class="itin.tipo_servicio == 1 ? 'bg-info' : 'bg-navy'"
                    >
                      {{ nombreTipoServicio(itin.tipo_servicio) }}
                    </span>
                  </td>
                  <td>{{ itin.fecha_ini }}</td>
                  <td>{{ itin.ruta ? itin.ruta.nombre : "" }}</td>
                  <td>{{ itin.tipo_vehiculo.nombre }}</td>
                  <td :class="itin.transportadora_id ? '' : 'text-danger'">
                    {{ itin.transportadora_id ? itin.transportadora_id.razon_social : "Sin definir" }}
                  </td>
                  <td class="text-center">
                    <div v-for="ceco_afe in itin.cecosAfes" :key="ceco_afe.id">
                      <small>{{ ceco_afe.numero }}</small>
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-sm bg-navy"
                        data-toggle="modal"
                        data-target="#modal_itinerario"
                        @click="getDatosModal(itin, index, itin.id)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        v-if="index > 0 && !itin.id"
                        @click="borrarItinerario(index)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal de crear o editar itinerario -->
      <div
        class="modal fade"
        id="modal_itinerario"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">
                {{ modal_title }} Itinerario
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-md-2">
                  <label for="tipo_presupuesto">Tipo Servicio</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="formItin.tipo_servicio"
                    :class="
                      $v.formItin.tipo_servicio.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="
                      formItin.id || $parent.origen_agrupamiento == 'SOTT'
                    "
                    @change="listaRutas"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="ts in $parent.listasForms.tipos_sevicios"
                      :key="ts.numeracion"
                      :value="ts.numeracion"
                    >
                      {{ ts.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="tipo_vehiculo">Tipo Vehículo</label>
                  <v-select
                    :class="[
                      $v.formItin.tipo_vehiculo.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.tipo_vehiculo"
                    placeholder="Tipo Vehiculo"
                    label="nombre"
                    :options="$parent.listasForms.tipo_vehiculos"
                    
                  ></v-select>
                </div>
                <div class="form-group col-md-6">
                  <label>Transportadora</label>
                  <v-select
                    :class="[
                      $v.formItin.transportadora.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.transportadora"
                    placeholder="Transportadora"
                    label="razon_social"
                    :options="$parent.listasForms.transportadoras"
                    class="form-control form-control-sm p-0"
                    @input="listaRutas"
                  ></v-select>
                </div>
                <div class="form-group col-md-6">
                  <label for="fecha_ini">Fecha y Hora Inicial</label>
                  <div class="row">
                    <div class="col-md-7">
                      <input
                        type="date"
                        v-model="formItin.fecha_ini"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formItin.fecha_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @blur="validaFechasItinerario"
                      />
                    </div>
                    <div class="col-md-5">
                      <input
                        v-model="formItin.hora_ini"
                        type="text"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99:99',
                          hourFormat: '24',
                        }"
                        class="form-control form-control-sm"
                        :class="
                          $v.formItin.hora_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @blur="validaFechasItinerario"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="fecha_fin">Fecha y Hora Final</label>
                  <div class="row">
                    <div class="col-md-7">
                      <input
                        type="date"
                        v-model="formItin.fecha_fin"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formItin.fecha_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @blur="validaFechasItinerario"
                      />
                    </div>
                    <div class="col-md-5">
                      <input
                        v-model="formItin.hora_fin"
                        type="text"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99:99',
                          hourFormat: '24',
                        }"
                        class="form-control form-control-sm"
                        :class="
                          $v.formItin.hora_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @blur="validaFechasItinerario"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label>Dirección Origen</label>
                  <input
                    type="text"
                    v-model="formItin.direccion_origen"
                    class="form-control form-control-sm"
                    :class="
                      $v.formItin.direccion_origen.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Dirección Destino</label>
                  <input
                    type="text"
                    v-model="formItin.direccion_destino"
                    class="form-control form-control-sm"
                    :class="
                      $v.formItin.direccion_destino.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Pasajeros</label>
                  <v-select
                    :class="[
                      $v.formItin.pasajeros.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.pasajeros"
                    placeholder="Pasajeros"
                    label="nombres"
                    class="form-control form-control-sm p-0"
                    :options="$parent.pasajeros"
                    :filterable="true"
                    multiple
                  ></v-select>
                </div>
              </div>
              <!-- Datos para itinerario nacional -->
              <div class="row" v-if="formItin.tipo_servicio == 1">
                <div class="form-group col-md-12">
                  <label for="tipo_vehiculo">Ruta</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="formItin.ruta"
                    placeholder="Ruta"
                    label="nombre"
                    :options="$parent.listasForms.rutas"
                    class="form-control form-control-sm p-0"
                    v-if="$parent.listasForms.rutas.length > 0"
                  ></v-select>
                  <div v-else>
                    <span class="text-danger"
                      >No se encontraron rutas para asignar</span
                    >
                  </div>
                </div>
              </div>
              <!-- Datos para itinerario interno -->
              <div class="row" v-if="formItin.tipo_servicio == 2">
                <div class="form-group col-md-9">
                  <label>Sitio</label>
                  <v-select
                    :class="[
                      $v.formItin.sitio.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.sitio"
                    placeholder="Sitio"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :options="$parent.listasForms.sitios"
                    :filterable="true"
                  ></v-select>
                </div>
                <div class="form-group col-md-3">
                  <label>Tipo Operación</label>
                  <v-select
                    :class="[
                      $v.formItin.tipo_operacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.tipo_operacion"
                    placeholder="Tipo Operación"
                    label="descripcion"
                    class="form-control form-control-sm p-0"
                    :options="$parent.listasForms.tipo_operacion"
                    :filterable="true"
                  ></v-select>
                </div>
                <div class="form-group col-md-6">
                  <label>Área</label>
                  <v-select
                    :class="[
                      $v.formItin.area.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.area"
                    placeholder="Área"
                    label="descripcion"
                    class="form-control form-control-sm p-0"
                    :options="$parent.listasForms.areas"
                    :filterable="true"
                  ></v-select>
                </div>
                <div class="form-group col-md-6">
                  <label>Turno</label>
                  <v-select
                    :class="[
                      $v.formItin.turno.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.turno"
                    placeholder="Turnos"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :options="$parent.listasForms.turnos"
                    :filterable="true"
                  ></v-select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Ceco Afe</label>
                  <v-select
                    :class="[
                      $v.formItin.cecos_afes.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.cecos_afes"
                    placeholder="Ceco Afe"
                    label="numero"
                    class="form-control form-control-sm p-0"
                    :options="$parent.listasForms.cecos_afes"
                    :filterable="true"
                    multiple
                    @input="calcularCecoAfeItinerario()"
                  ></v-select>
                </div>
                <div class="col-md-12 mb-0 text-center"
                  v-if="formItin.cecos_afes.length > 0"
                  :class="
                    total_porcentaje_itin == 100
                      ? 'text-success'
                      : total_porcentaje_itin < 100
                      ? 'text-muted'
                      : 'text-danger'
                  "
                >
                  <h4>Porcentaje Total: {{ total_porcentaje_itin }}%</h4>
                </div>
                <div class="form-group col-md-12"
                  v-if="formItin.cecos_afes.length > 0"
                >
                  <table
                    id="tabla-pasajeros"
                    class="table table-bordered table-striped table-hover table-condensed table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">Número</th>
                        <th class="text-center">Responsable</th>
                        <th class="text-center">Tipo Presupuesto</th>
                        <th class="text-center">Porcentaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cecoAfe, index) in formItin.cecos_afes"
                        :key="index"
                      >
                        <td class="text-center">{{ cecoAfe.numero }}</td>
                        <td class="text-nowrap">
                          {{
                            cecoAfe.funcionario
                              ? `${cecoAfe.funcionario.nombres} ${cecoAfe.funcionario.apellidos}`
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          <div v-if="cecoAfe.tipo_presupuesto == 1">
                            <h5>
                              <span class="badge badge-warning">Afe</span>
                            </h5>
                          </div>
                          <div v-else-if="cecoAfe.tipo_presupuesto == 2">
                            <h5>
                              <span class="badge badge-success">Ceco</span>
                            </h5>
                          </div>
                        </td>
                        <td class="p-1">
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="formItin.cecos_afes[index].porcentaje"
                              @blur="validaPorcentajeCecoAfeItinerario(index)"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text form-control-sm"
                                >%</span
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-group col-md-12">
                  <label>Responsable</label>
                  <v-select
                    :class="[
                      $v.formItin.user.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="formItin.user"
                    placeholder="Responsable"
                    label="name"
                    class="form-control form-control-sm p-0"
                    :options="$parent.users"
                    :filterable="true"
                  ></v-select>
                </div>
                <div class="form-group col-md-12">
                  <label>Observaciones</label>
                  <input
                    type="text"
                    v-model="formItin.observacion"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-if="!$v.formItin.$invalid && total_porcentaje_itin == 100"
                @click="addItinerario()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SolicitudesNacionalesEmpresa",
  components: {
    vSelect,
  },
  data() {
    return {
      modal_title: null,
      metodo: "",
      total_porcentaje_itin: 0,
      cecosAfesItin: [],
      formItin: {
        tipo_servicio: null,
        fecha_ini: null,
        fecha_fin: null,
        hora_ini: null,
        hora_fin: null,
        tipo_vehiculo: null,
        transportadora: null,
        direccion_origen: null,
        direccion_destino: null,
        cecos_afes: [],
        pasajeros: [],
        ruta: null,
        sitio: null,
        tipo_operacion: null,
        area: null,
        turno: null,
        user: null,
      },
    };
  },
  validations() {
    switch (this.formItin.tipo_servicio) {
      case 1:
        return {
          formItin: {
            tipo_servicio: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            hora_ini: {
              required,
            },
            hora_fin: {
              required,
            },
            tipo_vehiculo: {
              required,
            },
            transportadora: {
              required,
            },
            direccion_origen: {
              required,
            },
            direccion_destino: {
              required,
            },
            cecos_afes: {
              required,
            },
            pasajeros: {
              required,
            },
            user: {
              required,
            },
          },
        };

      case 2:
        return {
          formItin: {
            tipo_servicio: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            hora_ini: {
              required,
            },
            hora_fin: {
              required,
            },
            tipo_vehiculo: {
              required,
            },
            transportadora: {
              required,
            },
            direccion_origen: {
              required,
            },
            direccion_destino: {
              required,
            },
            cecos_afes: {
              required,
            },
            pasajeros: {
              required,
            },
            user: {
              required,
            },
            sitio: {
              required,
            },
            tipo_operacion: {
              required,
            },
            area: {
              required,
            },
            turno: {
              required,
            },
          },
        };

      default:
        return {
          formItin: {
            tipo_servicio: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            hora_ini: {
              required,
            },
            hora_fin: {
              required,
            },
            tipo_vehiculo: {
              required,
            },
            transportadora: {
              required,
            },
            direccion_origen: {
              required,
            },
            direccion_destino: {
              required,
            },
            cecos_afes: {
              required,
            },
            pasajeros: {
              required,
            },
            user: {
              required,
            },
          },
        };
    }
  },

  methods: {
    nombreTipoServicio(tipo_servicio) {
      if (this.$parent.listasForms.tipos_sevicios.length > 0) {
        const servicio = this.$parent.listasForms.tipos_sevicios.find(
          (ts) => ts.numeracion === tipo_servicio
        );
        return servicio.descripcion;
      }
    },

    getDatosModal(itin = null, index = null, id = null) {
      // Se reinicia el formulario
      this.modal_title = `Crear`;
      this.formItin = {
        index,
        id,
        tep_solicitud_id: this.$parent.itinerarios[0].tep_solicitud_id,
        tipo_servicio: this.$parent.origen_agrupamiento == "SOTT" ? 1 : null,
        fecha_ini: null,
        fecha_fin: null,
        hora_ini: null,
        hora_fin: null,
        tipo_vehiculo: this.$parent.form1[0].tipo_vehiculo,
        transportadora: null,
        direccion_origen: null,
        direccion_destino: null,
        cecos_afes: [],
        pasajeros: [],
        ruta: [],
        sitio: null,
        tipo_operacion: null,
        area: null,
        turno: null,
        observacion: null,
        user: [],
      };

      // Si es para editar se llena el formulario con los datos del itinerario
      if (itin) {
        // Se filtra el contenido de la fecha hora de inicio
        this.modal_title = `Editar`;
        let fecha_ini = itin.fecha_ini ? itin.fecha_ini.slice(0, 10) : "";
        let hora_ini = itin.fecha_ini ? itin.fecha_ini.slice(11, 19) : "";

        // Se filtra el contenido de la fecha hora de fin
        let fecha_fin = itin.fecha_fin ? itin.fecha_fin.slice(0, 10) : "";
        let hora_fin = itin.fecha_fin ? itin.fecha_fin.slice(11, 19) : "";

        this.formItin = {
          index,
          id,
          tep_solicitud_id: itin.tep_solicitud_id,
          tipo_servicio: itin.tipo_servicio,
          fecha_ini,
          fecha_fin,
          hora_ini,
          hora_fin,
          tipo_vehiculo: itin.tipo_vehiculo,
          transportadora: itin.transportadora_id,
          direccion_origen: itin.direccion_origen,
          direccion_destino: itin.direccion_destino,
          cecos_afes: itin.cecosAfes,
          pasajeros: itin.pasajeros,
          ruta: itin.ruta,
          sitio: itin.sitio,
          tipo_operacion:
            typeof itin.tipo_operacion == "object"
              ? itin.tipo_operacion
              : itin.tipo_operacion
              ? this.$parent.listasForms.tipo_operacion.find((to) => to.numeracion == itin.tipo_operacion)
              : null,
          area:
            typeof itin.area == "object"
              ? itin.area
              : itin.area
              ? this.$parent.listasForms.areas.find((a) => a.numeracion == itin.area)
              : null,
          turno: itin.turno,
          user:
            typeof itin.user == "object"
              ? itin.user
              : itin.user
              ? this.$parent.users.find((u) => u.id == itin.user)
              : null,
          observacion: itin.observacion,
        };
      }
      this.calcularCecoAfeItinerario();
      this.listaRutas();
    },

    async calcularCecoAfeItinerario() {
      this.cecosAfesItin = [];
      let count_porcentaje = 0;

      const length = this.formItin.cecos_afes.length - 1;
      // Deduccion de porcentaje
      const deduccion = parseFloat(100) - parseFloat(length);
      this.formItin.cecos_afes.forEach((cecoafe, index) => {
        if (this.formItin.cecos_afes.length == 1) {
          cecoafe.porcentaje = 100;
          this.cecosAfesItin.push(100);
        } else {
          if (parseFloat(cecoafe.porcentaje) == 100 && index == 0) {
            cecoafe.porcentaje -= this.formItin.cecos_afes.length - 1;
            this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
          } else if (
            parseFloat(cecoafe.porcentaje) &&
            count_porcentaje + parseFloat(cecoafe.porcentaje) <= deduccion
          ) {
            this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
          } else {
            if (parseFloat(cecoafe.porcentaje) >= deduccion) {
              if (index == 0) {
                cecoafe.porcentaje = deduccion;
                this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
              } else {
                this.cecosAfesItin.push(1);
                cecoafe.porcentaje = 1;
                let flag = true;
                this.formItin.cecos_afes.forEach((ca) => {
                  if (flag) {
                    if (ca.porcentaje > 1) {
                      flag = false;
                      ca.porcentaje -= 1;
                      count_porcentaje -= 1;
                    }
                  }
                });
              }
            } else if (count_porcentaje < deduccion) {
              this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
            } else {
              this.cecosAfesItin.push(1);
              cecoafe.porcentaje = 1;
            }
          }
        }
        count_porcentaje += parseFloat(cecoafe.porcentaje);
      });
      this.total_porcentaje_itin = count_porcentaje;
    },

    validaFechasItinerario() {
      if (
        this.formItin.fecha_ini &&
        this.formItin.hora_ini &&
        this.formItin.fecha_fin &&
        this.formItin.hora_fin
      ) {
        const fecha_menor = new Date(`${this.formItin.fecha_ini} ${this.formItin.hora_ini}`);
        const fecha_mayor = new Date(`${this.formItin.fecha_fin} ${this.formItin.hora_fin}`);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.formItin.fecha_fin = null;
          this.formItin.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.formItin.hora_fin) {
        if (this.formItin.hora_fin >= "20:00:00") {
          this.$swal({
            text:
              "Tenga en cuenta que el recorrido de FRONTERA va hasta las 20:00.!!",
            icon: "info",
            confirmButtonText: "Aceptar!",
          });
        }
      }
      this.listaRutas();
    },

    async validaPorcentajeCecoAfeItinerario(index) {
      let msg_error = null;
      const porcentaje_inicial = parseFloat(this.cecosAfesItin[index]);

      if (
        this.formItin.cecos_afes[index].porcentaje &&
        this.formItin.cecos_afes[index].porcentaje > 0
      ) {
        await this.calcularCecoAfeItinerario();
        if (this.total_porcentaje_itin > 100) {
          msg_error = {
            title: `Excedio la cantidad de ceco y afe permitida.`,
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar...`,
          };
        }
      } else {
        msg_error = {
          title: `Se debe diligenciar un porcentaje para el AFE o Ceco.`,
          text: `Por favor diligencie el campo...`,
        };
      }

      if (msg_error) {
        this.$swal({
          title: msg_error.title,
          text: msg_error.text,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.formItin.cecos_afes[index].porcentaje = parseFloat(
          porcentaje_inicial
        );
      }

      // Se reinicia el array de apoyo del formulario de cecos-afes
      this.cecosAfesItin[index] = parseFloat(
        this.formItin.cecos_afes[index].porcentaje
      );
      await this.calcularCecoAfeItinerario();
    },

    listaRutas() {
      this.$parent.listasForms.rutas = [];
      if (
        this.formItin.tipo_servicio == 1 &&
        this.formItin.transportadora &&
        this.formItin.fecha_ini
      ) {
        const filtros = {
          transportadora: this.formItin.transportadora.id,
          fecha_ini: this.formItin.fecha_ini,
        };
        axios
          .get("/api/tep/solicitudesNacionales/listaRutas", {
            params: filtros,
          })
          .then((response) => {
            this.$parent.listasForms.rutas = response.data;
          });
      }
    },

    addItinerario() {
      if (this.formItin.index === null) {
        this.$parent.itinerarios.push({
          id: null,
          tep_solicitud_id: this.formItin.tep_solicitud_id,
          cecosAfes: this.formItin.cecos_afes,
          det_tarifa_id: null,
          direccion_destino: this.formItin.direccion_destino,
          direccion_origen: this.formItin.direccion_origen,
          fecha_fin: `${this.formItin.fecha_fin} ${this.formItin.hora_fin}`,
          fecha_ini: `${this.formItin.fecha_ini} ${this.formItin.hora_ini}`,
          pasajeros: this.formItin.pasajeros,
          sitio_id: null,
          tarifa_id: null,
          tep_ruta_id: null,
          tipo_servicio: this.formItin.tipo_servicio,
          tipo_vehiculo: this.formItin.tipo_vehiculo,
          tipo_vehiculo_id: this.formItin.tipo_vehiculo.id,
          transportadora_id: this.formItin.transportadora,
          turno_id: null,
          ruta: this.formItin.ruta,
          sitio: this.formItin.sitio,
          tipo_operacion: this.formItin.tipo_operacion,
          area: this.formItin.area,
          turno: this.formItin.turno,
          user: this.formItin.user,
          observacion: this.formItin.observacion,
        });
        this.$parent.itinerarios[this.$parent.itinerarios.length - 1].index =
          this.$parent.itinerarios.length - 1;
      } else {
        this.$parent.itinerarios[this.formItin.index] = {};
        this.$parent.itinerarios[this.formItin.index] = {
          id: this.formItin.id,
          tep_solicitud_id: this.formItin.tep_solicitud_id,
          cecosAfes: this.formItin.cecos_afes,
          det_tarifa_id: null,
          direccion_destino: this.formItin.direccion_destino,
          direccion_origen: this.formItin.direccion_origen,
          fecha_fin: `${this.formItin.fecha_fin} ${this.formItin.hora_fin}`,
          fecha_ini: `${this.formItin.fecha_ini} ${this.formItin.hora_ini}`,
          pasajeros: this.formItin.pasajeros,
          sitio_id: null,
          tarifa_id: null,
          tep_ruta_id: null,
          tipo_servicio: this.formItin.tipo_servicio,
          tipo_vehiculo: this.formItin.tipo_vehiculo,
          tipo_vehiculo_id: this.formItin.tipo_vehiculo.id,
          transportadora_id: this.formItin.transportadora,
          turno_id: null,
          ruta: this.formItin.ruta,
          sitio: this.formItin.sitio,
          tipo_operacion: this.formItin.tipo_operacion,
          area: this.formItin.area,
          turno: this.formItin.turno,
          user: this.formItin.user,
          observacion: this.formItin.observacion,
        };
      }
      this.getDatosModal(null, null, this.formItin.id);
      this.validaItinerarios();
      this.$parent.getFechasTransporte();
      this.$refs.closeModal.click();
    },

    async validaItinerarios() {
      this.$parent.flagItin = true;

      await this.$parent.itinerarios.forEach((itin) => {
        if (itin) {
          // Se filtra el contenido de la fecha hora de inicio
          let fecha_ini = "";
          let hora_ini = "";
          if (itin.fecha_ini) {
            fecha_ini = itin.fecha_ini.slice(0, 10);
            hora_ini = itin.fecha_ini.slice(11, 19);
          }

          // Se filtra el contenido de la fecha hora de fin
          let fecha_fin = "";
          let hora_fin = "";
          if (itin.fecha_fin) {
            fecha_fin = itin.fecha_fin.slice(0, 10);
            hora_fin = itin.fecha_fin.slice(11, 19);
          }

          this.formItin = {
            tipo_servicio: itin.tipo_servicio,
            fecha_ini,
            fecha_fin,
            hora_ini,
            hora_fin,
            tipo_vehiculo: itin.tipo_vehiculo,
            transportadora: itin.transportadora_id,
            direccion_origen: itin.direccion_origen,
            direccion_destino: itin.direccion_destino,
            cecos_afes: itin.cecosAfes,
            pasajeros: itin.pasajeros,
            ruta: itin.ruta,
            sitio: itin.sitio,
            tipo_operacion: itin.tipo_operacion,
            area: itin.area,
            turno: itin.turno,
            user: itin.user,
            observacion: itin.observacion,
          };
          if (this.$v.formItin.$invalid) {
            this.$parent.flagItin = false;
          }
        }
      });
    },

    borrarItinerario(index) {
      this.$swal({
        title: "Está seguro de quitar este Itinerario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          // Borramos de la lista
          this.$parent.itinerarios.splice(index, 1);
          this.$parent.getFechasTransporte();
        }
      });
    },
  },
};
</script>
